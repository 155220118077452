<template>
  <div class="pay_wrap" >
    <div class="title" >
      {{ data.deviceName }}
<!--      <span>熏蒸舱</span>-->
    </div>
    <div class="status_text" >设备现在：{{ data.isUseStr }}</div>
    <div class="device_img_wrap" >
      <img src="./img/2.png" v-if="data.deviceType == '1'" />
      <img src="./img/3.png" v-if="data.deviceType == '2'" />
      <div class="device_text" >设备编号:{{ sn }}</div>
    </div>

    <div class="bottom_wrap" >
<!--      <div class="pay_item pay_active" >
        <div class="pay_left" >
          <div>套餐时长</div>
          <div>30分钟</div>
        </div>
        <div class="pay_money" >￥169.00</div>
      </div>-->

      <div class="pay_item" v-for="(item, index) in data.deviceProductVoList" :key="index"
           :class="{ 'pay_active': payIndex == index }"
           @click="choosePay(index)"
      >
        <div class="pay_left" >
          <div>{{ item.productName }}</div>
          <div>{{ item.useDuration }}分钟</div>
        </div>
        <div class="pay_money" v-if="item.level" >
          ￥{{ item.vipPrice }}
          <span class="real_money" >￥{{ item.price }}</span>
        </div>
        <div class="pay_money" v-else >￥{{item.price}}</div>
      </div>
      <div class="pay_bottom_wrap" v-if="payIndex > -1 && count > 0" >
        <div class="free_button" @click="payFree" >
          <div>￥0</div>
          <div>用券免费体验</div>
        </div>

        <van-button class="pay_button" @click="handlePay" >立即支付</van-button>
      </div>
      <van-button class="handle_pay" @click="handlePay" v-else >立即支付</van-button>
    </div>

    <van-popup v-model="show" :close-on-click-overlay="false" >
      <div class="free_wrap" >
        <img src="./img/close.png" class="close_icon" @click="show = false" />
        <div class="title" >免费体验</div>
        <div class="free_content" >
          <div class="title" v-if="payIndex > -1" >{{ useData.productName }}<span>{{ data.deviceProductVoList[payIndex].useDuration }}分钟</span></div>
          <div class="money_text" >￥0.00</div>
          <div class="price_text"  v-if="payIndex > -1" >原价¥{{ data.deviceProductVoList[payIndex].level ? data.deviceProductVoList[payIndex].vipPrice : data.deviceProductVoList[payIndex].price }}</div>
          <div class="confirm_content" >
            <div>您确定本次使用免费体验券吗？</div>
            <div>使用后，剩余 <span>{{ count - 1 }}</span> 张</div>
          </div>
          <div class="confirm_button" @click="payFreeConfirm" >确认使用</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<style scoped lang="less">
@import "index";
</style>
<script>
import {
  getDevice,
  getSign,
  getCustomerCouponByShopProduct
} from '@/api/device'
import {
  addOrderDevice,
  addCouponFree
} from '@/api/order'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'
export default {
  name: 'pay',
  data() {
    return {
      sn: this.$route.query.sn,
      submitting: false,
      data: {
        isUseStr: '',
        deviceName: '',
        deviceProductVoList: [],
        deviceType: ''
      },
      payIndex: -1,
      show: false,
      couponList: [],
      useData: {},
      count: 0
    }
  },
  methods: {
    payFreeConfirm() {
      const { customerCouponId } = this.useData
      const { sn } = this
      let productId = this.data.deviceProductVoList[this.payIndex].productId
      addCouponFree({
        customerCouponId,
        sn,
        productId
      }).then(res => {
        if(res.success) {
          this.show = false
          Toast.loading('支付成功，等待支付结果...')
          setTimeout(() => {
            this.$router.replace({ name: 'order' })
          }, 5000)
        }else {
          Toast(res.msg)
        }
      })
    },
    getCustomerCouponByShopProduct() {
      const { shopId } = this.data
      let productId = this.data.deviceProductVoList[this.payIndex].productId
      console.log('productId:', productId)
      console.log('shopId:', shopId)
      getCustomerCouponByShopProduct({ productId, shopId, page: 1, pageSize: 50 }).then(res => {
        console.log('res:', res)
        if(res.success) {
          if(res.data.length > 0) {
            this.couponList = res.data
            this.useData = res.data[0]
            this.count = res.count
          }else {
            this.couponList = []
            this.count = 0
            this.useData = {

            }
          }

        }
      })
    },
    payFree() {
      this.show = true
    },
    handlePay() {
      const that = this
      if(this.payIndex == -1) {
        Toast('请选择支付套餐')
        return false
      }
      const { sn } = this
      this.submitting = true
      let result = this.data.deviceProductVoList[this.payIndex]
      addOrderDevice({
        sn,
        productId: result.productId
      }).then(res => {
        this.submitting = false
        if(res.success) {
          let data = res.data
          let values = {
            appId: data.appId,
            timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: data.paySign, // 支付签名
          }
          console.log('values:', {...values})
          wx.chooseWXPay({
            // appId: data.appId,
            // timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            // nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            // package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            // signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            // paySign: data.paySign, // 支付签名
            ...values,
            success: function (res) {
              Toast.loading('支付成功，等待支付结果...')
              setTimeout(() => {
                that.$router.replace({ name: 'orderList' })
              }, 5000)
            },
            cancel: function (res) {
              Toast('已取消支付');
            },
            fail: function (res) {
            },
            complete: function(res) {

            }
          });
        }else {
          Toast(res.msg)
        }
      })

    },
    choosePay(index) {
      this.payIndex = index
      this.getCustomerCouponByShopProduct()
    },
    getDevice() {
      const { sn } = this
      getDevice({ sn }).then(res => {
        if(res.success) {
          this.data = res.data
        }
      })
    },
    getSign() {
      getSign({ url: window.location.href}).then(res => {
        if(res.success) {
          let params = res.data
          wx.config({
            debug: false, // 生产环境需要关闭debug模式
            appId: params.appId, // appId通过微信服务号后台查看
            timestamp: params.timestamp, // 生成签名的时间戳
            nonceStr: params.nonceStr, // 生成签名的随机字符串
            signature: params.signature, // 签名
            jsApiList: [ // 需要调用的JS接口列表
              'chooseWXPay'
            ]
          })
        }
      })
    },
  },
  mounted() {
    this.getDevice()
    this.getSign()
  }
}
</script>
